import { useEffect } from "react";
import { useFilter } from "../../../hooks/useFilter";

export default function DashboardSupplementation() {
  const {
    setHideMicroAreaFilter,
    setHideBatchFilter,
    setDashboard,
    setSideBarOptionSelected
  } = useFilter();

  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("SUPPLEMENTATION")
  }, []);

  return (
    <div className="cattleMap_background">

    </div>
  );
}
