import { ReactComponent as CalendarIcon } from "../../../../assets/svg/icons/calendar.svg";
import { format } from "date-fns";
import { useFilter } from "../../../../hooks/useFilter";
import "../styles.css"

export function ViewDataCattleMap() {
    const {
        setModalCalendarFilter,
        rangeDays,
        balance,
        balanceCapacity
    } = useFilter();

    return (
        <section className="dashBoardInfo__container" style={{ marginTop: 30 }}>

            <div className="dashBoardInfoDatas__container">
                <div className="dataFilter__owner--container" onClick={() => setModalCalendarFilter(true)}>
                    <small>De</small>
                    <div>
                        {
                            rangeDays?.from ? (
                                <h6>{format(new Date(rangeDays.from), 'dd/MM/yyyy')}</h6>
                            ) : null
                        }
                        <CalendarIcon />
                    </div>
                </div>

                <div className="dataFilter__owner--container" onClick={() => setModalCalendarFilter(true)}>
                    <small>Até</small>
                    <div>
                        {
                            rangeDays?.to ? (
                                <h6>{format(new Date(rangeDays.to), 'dd/MM/yyyy')}</h6>
                            ) : null
                        }
                        <CalendarIcon />
                    </div>
                </div>
            </div>

            <div className="dashBoardInfo__owner--container">
                <small>Saldo inicial</small>
                <h6>{balance?.reduce((acc, item) => acc + item.opening_balance, 0)}</h6>
            </div>

            <div style={{ display: "flex", gap: 10 }}>
                <div className="dashBoardInfoSplited__owner--container">
                    <small>Nascimentos</small>
                    <h6>+{balance?.reduce((acc, item) => acc + item.birth, 0)}</h6>
                </div>
                <div className="dashBoardInfoSplited__owner--container">
                    <small>Mortes</small>
                    <h6>-{balance?.reduce((acc, item) => acc + item.death, 0)}</h6>
                </div>
            </div>

            <div style={{ display: "flex", gap: 10 }}>
                <div className="dashBoardInfoSplited__owner--container">
                    <small>Compras</small>
                    <h6>+{balance?.reduce((acc, item) => acc + item.shopping, 0)}</h6>
                </div>
                <div className="dashBoardInfoSplited__owner--container">
                    <small>Vendas</small>
                    <h6>-{balance?.reduce((acc, item) => acc + item.sales, 0)}</h6>
                </div>
            </div>

            <div className="dashBoardInfo__dark--container">
                <small>Saldo final</small>
                <h6>{balance?.reduce((acc, item) => acc + item.end_balance, 0)}</h6>
            </div>

            <div className="dashBoardInfoGreen__owner--container">
                <small>lotação (ua/ha)</small>
                <h6>{balanceCapacity ? (balanceCapacity?.reduce((acc, item) => acc + item.ua_total, 0) / balanceCapacity?.reduce((acc, item) => acc + item.total_area, 0)).toFixed(2) : null}</h6>
            </div>

            <button
                className="dashBoardInfo__exportButton no-print"
                onClick={() => window.print()}
            >
                Exportar PDF
            </button>
        </section>
    );
}