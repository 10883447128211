import { MenuContainer } from "./MenuContainer/Index";
import { MenuOption } from "./MenuOption/Index";
import "./styles.css";
import { ReactComponent as Mapa } from "../../../assets/svg/icons/mapa.svg";
import { ReactComponent as Dados } from "../../../assets/svg/icons/dados.svg";
import { ReactComponent as Animais } from "../../../assets/svg/icons/animais.svg";
import logo from "../../../assets/svg/logo/logoPastejo.svg";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Constants from "../../../constants/Index";
import { useFilter } from "../../../hooks/useFilter";
import jwt_decode from "jwt-decode";
import { logout } from "../../../services/auth";
import { IOptionsSideBarLinks } from "../../../contexts/FilterContext";
import { ButtonWithChildren } from "../../Buttons/ButtonWithChildren/Index";
import { ReactComponent as HatIcon } from "../../../assets/svg/icons/hat.svg";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { TasksModals } from "../../Tasks/TasksModals/Index";

const SIDEBAR_LINK_THEME = {
  selected: {
    color: '#ffffff'
  },
  normal: {
    color: '#999999',
  },
  disabled: {
    color: '#999999',
    cursor: "not-allowed"
  }
}

const BUTTON_MAP_THEME = {
  selected: {
    background: '#57ce71'
  },
  normal: {
    background: '#ffffff'
  }
}

enum SIDEBAR_NAMES {
  PASTURE_EVALUATION = 'PASTURE_EVALUATION',
  MANAGER_SYSTEM = 'MANAGER_SYSTEM',
  PROFILES = 'PROFILES',
  LOGOUT = 'LOGOUT',
}

interface SideBarLinkComponentProps {
  sideBarName: IOptionsSideBarLinks
  label: string
  href?: string,
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  disabled?: boolean;
}

export function SideBar() {
  const [isOpen, setIsOpen] = useState(true);
  const { sideBarOptionSelected, setSideBarOptionSelected } = useFilter();
  const [permission, setPermission] = useState<boolean>(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { setShowTasklistModal } = useTaskCreation();

  function handleSideBarLink(sideBarName: IOptionsSideBarLinks, href?: string) {
    setSideBarOptionSelected(sideBarName)
    if (href) navigate(href)
  }

  function userLogout() {
    logout()
  }

  const SideBarLinkComponent = ({ href, sideBarName, onClick, label, disabled = false }: SideBarLinkComponentProps) => {
    return (
      <div className="sideBar_footer">
        <span
          style={disabled ? SIDEBAR_LINK_THEME.disabled : (sideBarOptionSelected == sideBarName ? SIDEBAR_LINK_THEME.selected : SIDEBAR_LINK_THEME.normal)}
          onClick={(event) => {
            onClick ? onClick(event) : handleSideBarLink(sideBarName, href)
            navigate('/mapadepastos')
          }}
        >
          {label}
        </span>
      </div>
    )
  }

  useEffect(() => {
    try {
      const decode: any = jwt_decode(token || " ")

      switch (decode.ROLE) {
        case "MASTER":
          setPermission(true)
          break;
        case "GERENTE":
          setPermission(true)
          break
        default:
          setPermission(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, []);

  var packageJsonInfo = require('../../../../package.json');

  return (
    <>
      <div className={`${isOpen ? "SideBarShow" : "SideBarHide"} no-print`}>
        <div className="sideBar_header">
          <img src={logo} alt="logo pastejo" />
        </div>
        <div className="sideBar_header_buttons">
          <Link
            style={sideBarOptionSelected === 'MAPA' ? BUTTON_MAP_THEME.selected : BUTTON_MAP_THEME.normal}
            onClick={() => setSideBarOptionSelected('MAPA')}
            className="button__mapa"
            to={"/mapadepastos"}
          >
            <Mapa />
            <strong>VER MAPA</strong>
          </Link>
        </div>

        <MenuContainer
          icon={Dados}
          title="Dashboard"
          borderColor="#57ce71"
        >
          <MenuOption
            name={Constants.PADDOCKS_MAP_DASHBOARD_CATTLE_MAP}
            to={Constants.PADDOCKS_MAP_DASHBOARD_CATTLE_MAP}
            title="Mapa de Gado"
            onClicked={() => setSideBarOptionSelected('DASHBOARD')}
          />

          <MenuOption
            name={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION}
            to={Constants.PADDOCKS_MAP_DASHBOARD_SUPPLEMENTATION}
            title="Suplementação"
          />
        </MenuContainer>

        <MenuContainer
          icon={Animais}
          title="Manejo de Pasto"
          borderColor="#62B8AE"
        >
          <MenuOption
            name={Constants.PADDOCKS_MAP_OCCUPATION}
            to={Constants.PADDOCKS_MAP_OCCUPATION}
            title="Ocupação dos Pastos"
          />
          {/* <MenuOption
            name={Constants.PADDOCKS_MAP_GRAZING_ROUTES}
            to={Constants.PADDOCKS_MAP_GRAZING_ROUTES}
            title="Rota de Pastejo"
          /> */}
          <MenuOption
            name={Constants.PADDOCKS_MAP_CAPACITY}
            to={Constants.PADDOCKS_MAP_CAPACITY}
            title="Lotação média"
          />
          <MenuOption
            name={Constants.PADDOCKS_MAP_PADDOCK_TIME}
            to={Constants.PADDOCKS_MAP_PADDOCK_TIME}
            title="Tempo no pasto"
          />
          <MenuOption
            name={Constants.PADDOCKS_MAP_PASTURE_MANAGEMENT}
            to={Constants.PADDOCKS_MAP_PASTURE_MANAGEMENT}
            title="Tipo de Manejo"
          />
        </MenuContainer>

        <div className="footerContent">
          {permission && (
            <>
              <div className="createTask_buttonWrapper">
                <ButtonWithChildren theme={"info"} onClick={() => { setShowTasklistModal(true) }}  >
                  <div className="buttonWithChildren--content-inline button__createTask">
                    <HatIcon />
                    <h6>Criar tarefas</h6>
                  </div>
                </ButtonWithChildren>
              </div>
              <SideBarLinkComponent sideBarName={SIDEBAR_NAMES.PASTURE_EVALUATION} label="Avaliação de pastagem" />
              <SideBarLinkComponent sideBarName={SIDEBAR_NAMES.MANAGER_SYSTEM} label="Gerenciar sistema" />
              <SideBarLinkComponent sideBarName={SIDEBAR_NAMES.PROFILES} label="Gerenciar perfis" />
            </>
          )}
          <SideBarLinkComponent onClick={userLogout} sideBarName={SIDEBAR_NAMES.LOGOUT} label="Sair" />
        </div>
        <div className="divider" style={{ marginTop: 20 }} />
        <div className="sideBar_footer_version">
          <span>Versão {packageJsonInfo.version}</span>
        </div>
      </div>
      <TasksModals />
    </>
  );
}
