import { Chart as ElementChart } from 'react-chartjs-2';
import Chart, { ChartData } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

interface Props {
    optionsColumnDry: LabelElement[];
    optionsColumnRain: LabelElement[];
    optionsLine: LabelElement[];
}

interface LabelElement {
    label: string[] | string;
    value: number;
}

export default function name({ optionsColumnDry, optionsColumnRain, optionsLine }: Props) {

    Chart.register(ChartDataLabels);

    const charData: ChartData = {
        labels: optionsColumnDry ? optionsColumnDry.map((el) => el.label) : [],
        datasets: [
            {
                type: 'bar',
                backgroundColor: "#D9B43C",
                data: optionsColumnDry ? optionsColumnDry.map((el) => Number((typeof (el.value) == "number" ? el.value : 0).toFixed(2))) : [],
                borderWidth: 1,
                yAxisID: 'y1',
                order: 1
            },
            {
                type: 'bar',
                backgroundColor: "#62B8AE",
                data: optionsColumnRain ? optionsColumnRain.map((el) => Number((typeof (el.value) == "number" ? el.value : 0).toFixed(2))) : [],
                borderWidth: 1,
                yAxisID: 'y2',
                order: 1
            },
            {
                type: 'line',
                backgroundColor: "#324440",
                borderColor: "#324440",
                data: optionsLine ? optionsLine.map((el) => Number((typeof (el.value) == "number" ? el.value : 0).toFixed(2))) : [],
                borderWidth: 2,
                yAxisID: 'y3',
                order: 0
            }
        ],
    };

    return (
        <div>
            <div>
                {optionsColumnDry && optionsColumnDry.every((el: any) => el?.value === 0) && optionsLine.every((el: any) => el?.value === 0) ?
                    <div className='noData'>
                        <span>Sem dados</span>
                    </div>
                    :
                    <div style={{
                        display: 'flex',
                    }}>
                        <div>
                            <div
                                style={{
                                    width: 162,
                                    height: "100%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    paddingLeft: 10,
                                    gap: 50
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 10
                                    }}
                                >
                                    <span>Nº de cabeças</span>
                                    <div className="chart-legend__item">
                                        <div className="chart-legend__icon" style={{
                                            backgroundColor: "#D9B43C",
                                            width: 50,
                                            height: 30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            Seca
                                        </div>
                                    </div>

                                    <div className="chart-legend__item">
                                        <div className="chart-legend__icon" style={{
                                            backgroundColor: "#62B8AE",
                                            width: 50,
                                            height: 30,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            Chuva
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 10
                                    }}
                                >
                                    <span>Taxa de lotação</span>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div className="chart-legend__icon" style={{
                                            backgroundColor: "#324440",
                                            width: 10,
                                            height: 10,
                                            borderRadius: "100%"
                                        }} />
                                        <div className="chart-legend__icon" style={{
                                            backgroundColor: "#324440",
                                            width: 32,
                                            height: 2,
                                        }} />
                                        <div className="chart-legend__icon" style={{
                                            backgroundColor: "#324440",
                                            width: 10,
                                            height: 10,
                                            borderRadius: "100%"
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ElementChart
                            id="chart"
                            type='bar'
                            data={charData}
                            height={300}
                            width={1010}
                            options={{
                                responsive: false,
                                devicePixelRatio: 4,
                                animation: {
                                    duration: 0
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                        labels: {
                                            boxWidth: 10,
                                        },
                                    },
                                    datalabels: {
                                        display: false,
                                    },
                                },
                                layout: {
                                    padding: {
                                        top: 10,
                                    }
                                },
                                scales: {
                                    x: {
                                        display: true,
                                        stacked: true,
                                        grid: {
                                            display: true,
                                        }
                                    },
                                    y1: {
                                        display: true,
                                        position: 'right',
                                    },
                                    y2: {
                                        display: false,
                                    },
                                    y3: {
                                        display: false,
                                    }
                                },
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    )
};