import { useEffect, useState } from "react";
import { useEditOnboarding } from "../../hooks/useEditOnboarding";
import ComponentInputSearch from "../InputSearch";
import { useFilter } from "../../hooks/useFilter";
import TableProducts from "../Table/Products";
import Pagination from "../Pagination";
import "./styles.css"
import mockProducts from "../../mocks/products.json"
import mockEvaluation from "../../mocks/evaluation.json"
import axios from "../../services/axios"
import TableEvaluation from "../Table/PastureEvaluator";
import ChipPressableComponent from "../Chips/ComponentChipPressable/Index";
import { useTaskCreation } from "../../hooks/useTaskCreation";
import { DateModal } from "../Modal/DateFilter";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import TableTaskEvaluation from "../Table/TaskEvaluation";
import { Loading } from "../Loading/Index";

export type IOptionsEvaluation =
  | "FINISHED"
  | "COMPLETED"
  | "PENDING"
  | "ERROR"
  | "ACCEPTED"

export function ViewTables() {

  const {
    queryToSearch,
    setQueryToSearch,
    setFilterSelected
  } = useEditOnboarding();

  const { modalStartEvaluator } = useTaskCreation();
  const [searchable, setSearchable] = useState(true);
  const [havePagination, setHavePagination] = useState(false);
  const [listTasks, setListTasks] = useState<ITaskEvaluation[]>([]);
  const [listEvaluations, setListEvaluations] = useState<IMicroAreaEvaluation[]>([]);
  const [listProducts, setListProducts] = useState<IProducts[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<IOptionsEvaluation | undefined>(undefined);
  const [rangeDays, setRangeDays] = useState<DateRange | undefined>(undefined);
  const { sideBarOptionSelected, systemManager } = useFilter();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const quantity = new Map<string, number>()
  quantity.set("PRODUCTS", 8)
  quantity.set("PASTURE_EVALUATION", 7)
  quantity.set("TASK_EVALUATION", 7)

  const [optionSelected, setOptionSelected] = useState<null | string>(null);
  useEffect(() => {
    if (sideBarOptionSelected == "MANAGER_SYSTEM") {
      setOptionSelected(systemManager)
    } else if (sideBarOptionSelected == "PASTURE_EVALUATION" && systemManager) {
      setOptionSelected(systemManager)
    } else {
      setOptionSelected(sideBarOptionSelected)
    }
  }, [systemManager, sideBarOptionSelected]);


  useEffect(() => {
    if (optionSelected == "PRODUCTS") {
      setSearchable(false)
      axios.get(`products?size=${quantity.get("PRODUCTS")}&page=${currentPage - 1}`)
        .then(({ data }) => {
          setListProducts(data.content)
          setTotalPages(data.total_pages)
          setHavePagination(data.total_pages > 0)
        })
    } else if (optionSelected == "PASTURE_EVALUATION") {
      setFilterSelected("evaluations")
      setSearchable(true)
      axios.get(`tasks/pasture-evaluation?${filters ? `status=${filters}` : ""}&size=${quantity.get("PASTURE_EVALUATION")}&page=${currentPage - 1}${queryToSearch ? `&query=${queryToSearch}` : ""}&${rangeDays?.from ? `date_begin=${format(new Date(rangeDays?.from), `y-MM-dd`)}` : ""}&${rangeDays?.to ?
        `date_end=${format(new Date(rangeDays?.to), `y-MM-dd`)}` : ""}`)
        .then(({ data }) => {
          setListEvaluations(data.content)
          setTotalPages(data.total_pages)
          setHavePagination(data.total_pages > 0)
        })
    } else if (optionSelected == 'TASK_EVALUATION') {
      setIsLoading(true)
      setFilterSelected("task_evaluations")
      setSearchable(true)
      axios.get(`tasks/monitor?${filters ? `status=${filters}&` : ""}size=${quantity.get("PASTURE_EVALUATION")}&page=${currentPage - 1}${queryToSearch ? `&query=${queryToSearch}` : ""}${rangeDays?.from ? `&date_begin=${format(new Date(rangeDays?.from), `y-MM-dd`)}` : ""}${rangeDays?.to ?
        `&date_end=${format(new Date(rangeDays?.to), `y-MM-dd`)}` : ""}`)
        .then(({ data }) => {
          setListTasks(data.content)
          setTotalPages(data.total_pages)
          setHavePagination(data.total_pages > 0)
        })
        .finally(() => setIsLoading(false))
    }
  }, [modalStartEvaluator, optionSelected, totalPages, currentPage, queryToSearch, filters, rangeDays]);

  useEffect(() => {
    setCurrentPage(1)
  }, [filters, rangeDays, queryToSearch]);

  useEffect(() => {
    setCurrentPage(1)
  }, [modalStartEvaluator]);

  return (
    <>
      {/* Filtro */}
      <div className="filter__content">
        {optionSelected == "PASTURE_EVALUATION" && (
          <>
            <ChipPressableComponent
              theme={
                filters == undefined ? "menu-dark" : "menu-light"
              }
              label={"Todos"}
              onClick={() => setFilters(undefined)}
            />
            <ChipPressableComponent
              theme={filters == "COMPLETED" ? "menu-dark" : "menu-light"}
              label={"Avaliado"}
              onClick={() => setFilters("COMPLETED")}
            />
            <ChipPressableComponent
              theme={
                filters == "ERROR" ? "menu-dark" : "menu-light"
              }
              label={"Erro"}
              onClick={() => setFilters("ERROR")}
            />
            <ChipPressableComponent
              theme={
                filters == "PENDING" ? "menu-dark" : "menu-light"
              }
              label={"Pendente"}
              onClick={() => setFilters("PENDING")}
            />
          </>
        )}
        {optionSelected == "TASK_EVALUATION" && (
          <>
            <ChipPressableComponent
              theme={
                filters == undefined ? "menu-dark" : "menu-light"
              }
              label={"Todos"}
              onClick={() => setFilters(undefined)}
            />
            <ChipPressableComponent
              theme={filters == "COMPLETED" ? "menu-dark" : "menu-light"}
              label={"Concluido"}
              onClick={() => setFilters("COMPLETED")}
            />
            <ChipPressableComponent
              theme={
                filters == "ERROR" ? "menu-dark" : "menu-light"
              }
              label={"Erro"}
              onClick={() => setFilters("ERROR")}
            />
            <ChipPressableComponent
              theme={
                filters == "PENDING" ? "menu-dark" : "menu-light"
              }
              label={"Pendente"}
              onClick={() => setFilters("PENDING")}
            />
          </>
        )}

        {searchable && <>
          <div style={{ width: "100%" }}>
            <ComponentInputSearch
              onClick={(query: string | undefined) => setQueryToSearch(query)}
              type="usuário ou pasto"
              onChange={el => {
                if (el == "") {
                  setQueryToSearch(undefined)
                }
              }}
            />
          </div>
        </>}
      </div>
      {/* Filtro */}

      {/* Visualização dos avaliadores */}
      <div className="productsContainer">
        {optionSelected == "TASK_EVALUATION" ? <TableTaskEvaluation listTable={listTasks} filter={filters} isLoading={isLoading} /> : <></>}
        {optionSelected == "PASTURE_EVALUATION" ? <TableEvaluation listTable={listEvaluations} filter={filters} /> : <></>}
        {optionSelected == "PRODUCTS" ? <TableProducts listTable={listProducts} /> : <></>}
      </div>
      {/* Visualização dos avaliadores */}

      {/* Paginação */}
      {havePagination && (
        <>
          <div className="delimiter" style={{ marginTop: 30, marginBottom: 20 }} />
          <div className="paginator">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onClick={(value: number) => setCurrentPage(value)}
            />
          </div>
        </>
      )}
      {/* Paginação */}

      {/*Modals*/}
      <DateModal
        setRange={setRangeDays}
      />
      {/*Modals*/}
    </>
  );
}
